

<template>

<div>

    <div class="preloader" style="display: none;">
        <div class="lds-ripple">
            <div class="lds-pos"></div>
            <div class="lds-pos"></div>
        </div>
    </div>             
    <section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master bg-login-authentic">
        <div class="col-12 col-md-12 col-lg-3 text-center pt-4">
            <a href="/">
        <img :src="logo" alt="Logo" style="max-height: 66px;">
    </a>     </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-3 pt-4 order-2 order-md-2 order-lg-1">
                    <div class="card card-2 mb-0" style="background-color: var(--color-broker);">
        <div class="card-body pb-3">
            <a href="" title="">
                <div class="d-dash-ps">
                    <div class="mb-3 d-ico-dash-cliente">
                        <img src="@/assets/img/icons/login.png" class="ico-dash-cliente-5" alt="Qsuíte">
                    </div>
                    <div class="">
                        <h1 class="mb-1 font-weight-medium text-white font-17">Facilidades {{company_name}}</h1>
                        <p class="text-white pt-3">Cadastrados possuem facilidades como suporte para seus produtos, agendamento e status de visitas técnicas, além do gerenciamento de seus dados.</p>
                    </div>
                </div>
            </a>
        </div>
    </div>            </div>
                <div class="col-12 col-md-12 col-lg-9 order-1 order-md-1 order-lg-2 pt-4">
                    <form @submit="save" class="px-2 needs-validation">
                        <div class="col-12">
                            <div class="row">

                                <div class="col-12 pt-3"></div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>CRECI *</label>
                                        <input  required placeholder="CRECI" v-model="broker.creci"  type="text" class="form-control" >
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o CRECI.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Imobiliária *</label>
                                        <select required class="form-control selectsearch" style="height: 48px !important" v-model="broker.real_state_id">
                                            <option v-for="imobiliaria in imobiliarias" :value="imobiliaria.id">{{imobiliaria.fantasy_name}}</option>
                                        </select>   
                                    </div>                                               
                                </div>
                                <div v-if="imobiliaria_allow_others == 'sim'" class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Nome da imobiliária *</label>
                                        <input  required  v-model="broker.mercado_name" type="text" class="form-control" >  
                                    </div>                                               
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Nome * </label>
                                        <input  required placeholder="Digite seu nome" v-model="broker.first_name" required type="text" class="form-control" >
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu nome.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Sobrenome *</label>
                                        <input  required placeholder="Digite seu sobrenome" v-model="broker.last_name" required type="text" class="form-control" >
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu sobrenome.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>CPF *</label>
                                        <input required placeholder="999.999.999-99" v-model="broker.cpf" required type="tel" class="form-control" v-mask="'###.###.###-##'">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Data de nascimento *</label>
                                        <input  required type="date" v-model="broker.birth_date" class="form-control">
                                        <div  class="invalid-feedback pl-4 pb-3">Por favor! Informe o sua data de nascimento.</div>
                                    </div>
                                </div>
                                <div class="col-12"></div>
                                <br>
                                <div class="col-12 py-2">
                                    <h4 class="card-title">Contatos</h4>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>E-mail *</label>
                                        <input  required  v-model="broker.email" placeholder="email@example.com" type="email" class="form-control" value="abc@example.com">
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu e-mail.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Telefone </label>
                                        <div class="d-flex">
                                            <AreaCode :defaultCountry="broker.telephone_area_code_iso" @setIsoCode="setTelephoneIsoCode" @setAreaCode="setTelephoneAreaCode"/>
                                            <input type="tel"  class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.telephone" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Whatsapp *</label>
                                        <div class="d-flex">
                                            <AreaCode :defaultCountry="broker.whatsapp_area_code_iso"  @setIsoCode="setWhatsAppIsoCode" @setAreaCode="setWhatsAppAreaCode"/>
                                            <input type="tel"  required class="form-control" placeholder="(44) 4 4444-4444" v-model="broker.whatsapp" v-mask="['(##) ####-####', '(##) # ####-####']" >
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 py-2">
                                    <h4 class="card-title">Senha</h4>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Senha *</label>
                                        <input required v-model="broker.password" type="password" class="form-control" placeholder="********">
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe sua senha.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Repetir senha *</label>
                                        <input required v-model="broker.password_confirmation" type="password" class="form-control" placeholder="********">
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe sua senha.</div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="form-actions">
                                <div class="text-right">
                                    <button type="reset" @click="back()" class="btn btn-dark">Cancelar</button>
                                    <button type="submit" class="btn btn-info">Cadastrar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        <br>
    </section>
    <section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master">
          <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </section>
 <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados.  <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> |<a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <!-- <a href="https://qsuite.com.br" target="_blank"><img src="" alt="Qsuite" style="max-height: 23px;"></a> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>


</template>
    <script>
    
    import ThemeService from "@/services/resources/ThemeService";
    import ImobiliariaNoAuthService from "@/services/resources/ImobiliariaNoAuthService";
    import BrokerNoAuthService from "@/services/resources/BrokerNoAuthService";

    import AreaCode  from "@/components/AreaCode.vue";
    import Cookies from "js-cookie";
    import VerifyDataAvailableService from "@/services/resources/VerifyDataAvailableService";
    
    const dataAvailable = VerifyDataAvailableService.build();
    const serviceTheme = ThemeService.build();
    const brokerNoAuthService = BrokerNoAuthService.build();
    const imobiliariaNoAuthService = ImobiliariaNoAuthService.build()
    
    export default {
        components: {
                AreaCode
            },
        data() {
            return {
                site:"",
                logo: null,
                company_name: null,
                broker: {
                    first_name: '',
                    last_name: '',
                    cpf: '',
                    birth_date: '',
                    real_state_id: '',
                    email: '',
                    is_active: 1,
                    creci: '',
                    telephone: '',
                    whatsapp: '',
                    password: '',
                    password_confirmation: '',
                    whatsapp_area_code: "55",
                    whatsapp_area_code_iso: "BR",
                    telephone_area_code: "55",
                    telephone_area_code_iso: "BR"
                },
                imobiliaria_allow_others:  'não',
                imobiliarias: [],
                whatsapp_ok: true,
                email_ok: true,
                cpf_ok: true
               
            };
        },
        methods:{

            getRealImobiliarias() {
                let data = {
                    company_id: Cookies.get('company_id'),
                  
                };
                imobiliariaNoAuthService.search(data)
                .then(resp => {
                    this.imobiliarias = resp
                })
            },  

            allowOthers(allow){
                alert("oi")
               console.log(allow);
                this.imobiliaria_allow_others=allow
            },

            back() {
                this.$router.push('/');
            },
         
            setTelephoneAreaCode(value) {
            this.$set(this.broker, 'telephone_area_code', value);
        },
        setWhatsAppAreaCode(value) {
                this.$set(this.broker, 'whatsapp_area_code', value);
            },
            setTelephoneIsoCode(value) {
                this.$set(this.broker, 'telephone_area_code_iso', value);
            },
            setWhatsAppIsoCode(value) {
                this.$set(this.broker, 'whatsapp_area_code_iso', value);
            },
            save(e) {
                e.preventDefault();

                if(!this.email_ok || !this.cpf_ok || !this.whatsapp_ok) {
                    this.$bvToast.toast('Verifique se todos os campos estão preenchidos corretamente', {
                            title: 'Cadastro',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    return;
                }

                if(this.broker.password.length < 8 ) {

                    this.$bvToast.toast('Sua senha deve ser maior ou igual a 8 dígitos', {
                            title: 'Senha',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    return;
                }

                if(this.broker.password_confirmation !== this.broker.password) {

                    this.$bvToast.toast('Senhas não conferem', {
                            title: 'Senha',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    return;
                }

                brokerNoAuthService.create({...this.broker, company_id: Cookies.get('company_id')}) .then(resp => {
                this.$bvToast.toast('Cadastro realizado com sucesso!', {
                    title: 'Cadastro',
                    autoHideDelay: 5000,
                    type: 'success'
                    })
                    
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 5000);
                })

    
                .catch(err => {
                    this.$bvToast.toast('Cadastro já existe. Utilize outro email, CPF ou Whatsapp', {
                        title: 'Cadastro',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })
            },
           
            fetchTheme(){
                let data = {
                    domain: window.location.hostname
                };
    
                serviceTheme
                .search(data)
                .then(resp => {
                    resp.forEach(element => {
                        this.company_id = element.company_id;
                        switch(element.key){
                            case 'PORTAL_BROKER_SENTENCE':
                                this.sentence = element.value;
                                break;
                            case 'PORTAL_CLIENT_COMPONENT_COLOR':
                                this.component_color = element.value;
                                break;
                            case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                                this.background_color = element.value;
                                break;
                            case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                                this.active_register = element.value;
                                break;
                            case 'PORTAL_BROKER_BACKGROUND_DESKTOP':
                                this.desktop_background = `background-image: url("${element.value}")`;
                                break;
                            case 'PORTAL_BROKER_BACKGROUND_MOBILE':
                                this.mobile_background = `background-image: url("${element.value}")`;;
                                break;
                            case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                                this.active_register = element.value;
                                break;
                            case 'PORTAL_BROKER_ACTIVE_REGISTER':
                                this.broker.is_active = element.value;
                                break;
                            case 'SETTINGS_LOGO':
                                this.logo = element.value;
                                break;
                            case 'COMPANY_NAME':
                                this.company_name = element.value[0].toUpperCase() + element.value.substring(1);
                                break;
                        }
                    });
                });
            },
            
        },
        computed: {
            isMobile() {
                return this.width <= 768;
            },
        },
        mounted(){
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:4932490,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
             this.site=window.location.host
            this.getRealImobiliarias()
            this.fetchTheme();          

    
            $('.selectsearch').select2({
                "language":{
                        "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
            

            $(".selectsearch").on("change", (change)=> {  
                
                this.imobiliarias.forEach((ele) => {
                    if(ele.id == $(".selectsearch").val()){
                     this.imobiliaria_allow_others=ele.allow_others
                    }
                    
                });
               
                this.$data.broker.real_state_id = parseInt($(".selectsearch").val())
            }); 
        },
        watch: {
            
        }
    }
    </script>

<body>
   
    
    </body>