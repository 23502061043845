import Rest from '@/services/Rest';

/**
 * @typedef {ImobiliariaNoAuthService}
 */
export default class ImobiliariaNoAuthService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'no-auth/imobiliaria'
}
