import Rest from '@/services/Rest';

/**
 * @typedef {BrokerNoAuthService}
 */
export default class BrokeNoAuthService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'no-auth/broker'
}
