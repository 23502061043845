import { render, staticRenderFns } from "./CreateAccount.vue?vue&type=template&id=482e975e&"
import script from "./CreateAccount.vue?vue&type=script&lang=js&"
export * from "./CreateAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CreateAccount.vue?vue&type=custom&index=0&blockType=body"
if (typeof block0 === 'function') block0(component)

export default component.exports